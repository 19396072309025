<template>
  <div>
    <div
      no-body
      class="saj-title m-0 mt-1 mb-1"
      style=""
    >
      <validation-observer ref="validateDivision">
        <div class="p-1 d-flex justify-content-center">
          <p
            class="saj-header"
            style=""
          >
            {{ isUpdate ? $t("Edit Division") : $t("Add New Division") }}
            <!-- {{ division_id }} -->
            <!-- {{ subsidiary }} -->
            <!-- {{ division.item.subsidiary_id }} -->
          </p>
        </div>

        <!-- Division Details -->
        <b-row class="col-12 saj-text m-0">
          <!-- Division Name -->
          <b-col
            cols="3"
            class="p-1 d-flex align-items-center"
          >
            {{ $t("Division Name") }}:
          </b-col>
          <b-col
            cols="9"
            class="p-1"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Division Name')"
              rules="required"
            >
              <b-form-input
                v-model="division_name"
                class="saj-text"
                type="text"
                placeholder=""
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <!-- Assigned To -->
          <b-col
            v-if="isUpdate"
            cols="3"
            class="p-1 d-flex align-items-center"
          >
            {{ $t("Assigned To") }}:
          </b-col>
          <b-col
            v-if="isUpdate"
            cols="9"
            class="p-1 "
          >
            <v-select
              v-model="assigned_to"
              class="saj-text select-size-md"
              style="background: white; "
              label="text"
              :placeholder="$t('Choose Employee')"
              :reduce="val => val.value"
              :options="empList"
              :value="empList"
            />
          </b-col>

          <!-- Description -->
          <b-col
            cols="3"
            class="p-1 d-flex align-items-start"
          >
            {{ $t("Description") }}:
          </b-col>
          <b-col
            cols="9"
            class="p-1 "
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Description')"
              rules="required"
            >
              <textarea
                v-model="description"
                class="saj-text form-control flex-grow-1"
                name="textarea"
                style="height: 120px
                                  border: 1px solid #d8d6de;
                                  "
                rows="5"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <!-- Department -->
          <b-col
            cols="3"
            class="p-1 d-flex align-items-center"
          >
            {{ $t("Department") }}:
          </b-col>
          <b-col
            cols="9"
            class="p-1 "
          >
            <!-- {{ selectDepartment }} -->
            <v-select
              v-if="isUpdate"
              v-model="selectDepartment"
              class="saj-text select-size-md"
              style="background: white; "
              label="text"
              :placeholder="$t('Select Department')"
              multiple
              :reduce="val => val.value"
              :options="deptList"
              :value="deptList"
            />
            <validation-provider
              v-else
              #default="{ errors }"
              :name="$t('Department')"
              rules="required"
            >
              <v-select
                v-model="selectDepartment"
                class="saj-text select-size-md"
                style="background: white; "
                label="text"
                :placeholder="$t('Select Department')"
                multiple
                :reduce="val => val.value"
                :options="deptList"
                :value="deptList"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

        </b-row>
        <div class="justify-content-center mt-3">
          <div
            class="d-flex justify-content-center"
            style=""
          >
            <button
              class="btn btn-close m-1 saj-button"
              aria-label="Close modal"
              style="
                  color: white;
                  background: #ff0000;
                "
              @click="close()"
            >
              {{ $t('Cancel') }}
            </button>
            <button
              class="btn m-1 saj-button"
              aria-label="submit modal"
              style="
                  color: white;
                  background: #0b103c !important;
                  border-color: #0b103c !important;
                "
              @click="isUpdate ? editDivision() : addDivision()"
            >
              {{ $t('Save') }}
            </button>
          </div>
        </div>

      </validation-observer></div>

  </div></template>
<style>
</style>
<script>
import {
  BRow,
  BCol,
  BFormInput,
  //   BBadge,

} from "bootstrap-vue"
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import SAJToast from '@/component/saj-toastification.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    // BBadge,
  },
  props: {
    isUpdate: {
      type: Boolean,
      default: true,
    },
    division: {
      type: null,
      default: null,
    },
    subsidiary: {
      type: null,
      default: null,
    },
  },
  data() {
    return {
      required,
      division_name: this.division.item !== undefined ? this.division.item.name : "",
      assigned_to: this.division.item === undefined || this.division.item.head_of_division_id === null ? [] : this.division.item.head_of_division_id.full_name,
      description: this.division.item !== undefined ? this.division.item.details : "",
      selectDepartment: [],
      division_id: this.division.item !== undefined ? this.division.item.id : "",
      deptList: [],
      deptDivList: [],
      empList: [],
      divisionData: this.division,
      subsidiaryID: null,
      empListA: [],
      hod: null,

    }
  },
  computed: {
    isEnglish() {
      return this.$i18n.locale === 'en'
    },
  },
  mounted() {
    this.getDepartmentBySub()

    if (this.isUpdate){
      this.getEmployeeDivision()

      setTimeout(() => {
        this.getHeadOfDivision()
        this.getDepartmentDivision()
      }, 500)
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    getEmployeeDivision() {
      if (this.isUpdate){
        this.subsidiaryID = this.division.item.subsidiary_id
      } else {
        this.subsidiaryID = this.subsidiary
      }
      this.$axios.get(`${this.$baseUrl}/divisions/get_employee_by_grade_set?subsidiary_id=${this.subsidiaryID}&grade_set_id=1&status=active`).then(response => {
        const res1 = response.data.data
        const a = res1.map(emp => ({
          value: emp.id,
          text: emp.full_name,
        }))

        this.$axios.get(`${this.$baseUrl}/divisions/get_employee_by_grade_set?subsidiary_id=${this.subsidiaryID}&grade_set_id=2&status=active`).then(resp => {
          const res2 = resp.data.data
          const b = res2.map(emp => ({
            value: emp.id,
            text: emp.full_name,
          }))

          this.empList = a.concat(b)
        })
        // this.empList = this.allEmpName.map(emp => (
        //   emp.id
        // ))
        // this.assigned_to = this.empList
      })
    },
    getHeadOfDivision(){
      this.$axios.get(`${this.$baseUrl}/divisions/get_head_of_division?division_id=${this.division_id}`).then(response => {
        this.hod = response.data.data

        this.hod = new Array(this.hod)
        // console.log('array', this.hod)

        this.empListA = this.hod.map(dept => (
          dept.id ? dept.id : 'No People Assign'
        ))
        // .catch(() => {

        // })

        this.assigned_to = this.empListA
      })
    },
    getDepartmentBySub() {
      if (this.isUpdate){
        this.subsidiaryID = this.division.item.subsidiary_id
      } else {
        this.subsidiaryID = this.subsidiary
      }
      this.$axios.get(`${this.$baseUrl}/departments/get_departments_by_subsidiary?subsidiary_id=${this.subsidiaryID}`).then(response => {
        this.allDeptName = response.data.data
        this.deptList = this.allDeptName.map(dept => ({
          value: dept.id,
          text: dept.name,
        }))
      })
    },
    getDepartmentDivision(){
      this.$axios.get(`${this.$baseUrl}/divisions/get_all_divisions?division_id=${this.division_id}`).then(response => {
        this.allDeptDiv = response.data.data.departments
        this.deptDivList = this.allDeptDiv.map(div => (
          div.id
          // text: div.name,
        ))
        this.selectDepartment = this.deptDivList
        // console.log('display', this.selectDepartment)
      })
    },
    addDivision(){
      this.$refs.validateDivision.validate().then(success => {
        if (success){
          const data = new FormData()

          data.append('subsidiary_id', this.subsidiary)
          data.append('name', this.division_name)
          data.append('details', this.description)
          this.selectDepartment.forEach(dept => {
            data.append('department_id[]', dept)
          })

          // data.forEach(a => {
          //   console.log("append add", a)
          // })
          // console.log(this.isUpdate)
          this.$axios.post(`${this.$baseUrl}/divisions/store_divisions`, data)
            .then(() => {
              // data.forEach(a => {
              //   console.log("append", a)
              // })
              setTimeout(() => {
                this.$toast(
                  {
                    component: SAJToast,
                    props: {
                      title: `${this.$t('Division successfully added')}.`,
                      icon: 'CheckCircleIcon',
                      variant: 'success',
                      titleColor: '#000',
                    },
                  },
                  {
                    position: "top-right",
                    type: 'info',
                  },
                )
                this.$emit('reload-division')
                this.$emit('close')
              }, 1000)
            }).catch(error => {
              const res = error.response.data.data
              this.$toast(
                {
                  component: SAJToast,

                  props: {
                    title: this.isEnglish ? res.bi_msg : res.bm_msg,
                    icon: 'XIcon',
                    iconBg: '#e80202',
                    variant: 'success',
                    titleColor: '#000',
                  },
                },
                {
                  position: "top-right",
                  type: 'info',
                },
              )
            })
        }
      })
    },
    editDivision(){
      this.$refs.validateDivision.validate().then(success => {
        if (success){
          const data = new FormData()

          data.append('division_id', this.division_id)
          data.append('name', this.division_name)
          data.append('details', this.description)
          data.append('employee_id', this.assigned_to)
          // data.append('department_id[]', this.selectDepartment)
          this.selectDepartment.forEach(dept => {
            data.append('department_id[]', dept)
          })

          // data.forEach(a => {
          //   console.log("append edit", a)
          // })
          this.$axios.post(`${this.$baseUrl}/divisions/update_divisions`, data)
            .then(response => {
              const result = response.data
              if (result.success) {
                setTimeout(() => {
                  this.$toast(
                    {
                      component: SAJToast,
                      props: {
                        title: `${this.$t('Division successfully updated')}.`,
                        icon: 'CheckCircleIcon',
                        variant: 'success',
                        titleColor: '#000',
                      },
                    },
                    {
                      position: "top-right",
                      type: 'info',
                    },
                  )
                  this.$emit('reload-division')
                  this.$emit('close')
                }, 1000)
              }
            }).catch(error => {
              const res = error.response.data.data
              this.$toast(
                {
                  component: SAJToast,

                  props: {
                    title: this.isEnglish ? res.bi_msg : res.bm_msg,
                    icon: 'XIcon',
                    iconBg: '#e80202',
                    variant: 'success',
                    titleColor: '#000',
                  },
                },
                {
                  position: "top-right",
                  type: 'info',
                },
              )
            })
        }
      })
    },
  },
}
</script>
