<template>
  <div class="">
    <b-card
      no-body
      class="saj-title mr-1 pb-2"
      style=""
    >
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0"
          >
            <label class="saj-text">{{ $t("Subsidiary") }}:</label>
            <!-- {{ selectedSub }} -->
            <b-form-select
              v-model="selectedSub"
              size="md"
              class="saj-text"
              :options="subsidiaryList"
            />
          </b-col>

          <b-col
            cols="2"
            class="mt-2 justify-content-center"
          >
            <b-button
              variant="primary"
              class=""
              block
              style="margin-top: 7px"
              :disabled="selectedSub === null"
              @click="getDivision(), show = true"
            >

              <span class="saj-button d-md-none d-lg-block"> {{ $t("Search") }} </span>
            </b-button>
          </b-col>
          <b-col
            cols="2"
            class="mt-2"
          >
            <b-button
              variant="primary"
              class=""
              block
              style="margin-top: 7px"
              @click="clearSearch()"
            >
              <span class="saj-button d-md-none d-lg-block"> {{ $t("Clear") }}</span>
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- card -->
    <div
      v-if="show"
    >
      <b-card
        no-body
        class="saj-text mr-1 p-1"
        style=""
      >
        <div class="d-flex justify-content-end">
          <b-button
            v-b-modal.create-division
            variant="primary"
            class="saj-button"
          >
            {{ $t('Add New Division') }}
          </b-button>
        </div>
        <!-- Modal Add Division & Edit Division -->
        <b-modal
          id="create-division"
          :hide-footer="true"
          :hide-header="true"
          :centered="true"
          size="lg"
        >
          <CreateDivision
            :is-update="false"
            :subsidiary="selectedSub"
            :division="{}"
            @close="$bvModal.hide('create-division')"
            @reload-division=" getDivision()"
          />
        </b-modal>
        <b-modal
          id="edit-division"
          :hide-footer="true"
          :hide-header="true"
          :centered="true"
          size="lg"
        >
          <CreateDivision
            :division="divisionData"
            @close="$bvModal.hide('edit-division')"
            @reload-division=" getDivision()"
          />
        </b-modal>
        <b-modal
          id="delete-division"
          :hide-footer="true"
          :hide-header="true"
          :centered="true"
        >
          <deleteDiv
            confirm-btn="Yes"
            cancel-btn="No"
            :title="$t('Are you sure to delete this Division?')"
            @cancel="$bvModal.hide('delete-division')"
            @confirm="deleteDivision(), $bvModal.hide('delete-division')"
          />
        </b-modal>
        <div>
          <b-table
            show-empty
            :items="DivisionList"
            :fields="fields"
            responsive=""
            bordered
            class="mt-1 mb-1 saj-subtitle"
          >
            <!-- A custom formatted column -->
            <template #head()="data">
              <span
                class="saj-title"
                style=""
              >{{ $t(data.label) }}</span>
            </template>

            <template #empty="">
              <h4 style="text-align: center; font-style: italic">
                {{ $t('There are no records to show') }}
              </h4>
            </template>
            <template #cell(index)="data">
              <div
                class="saj-text d-flex justify-content-center"
              >
                {{ data.index + 1 }}
              </div>
            </template>
            <template #cell(division)="data">
              {{ data.item.name }}
            </template>
            <template #cell(person)="data">
              {{ data.item.head_of_division_id === null ? $t('No Person Assign') : data.item.head_of_division_id.full_name }}
            </template>
            <template #cell(action)="data">
              <div
                class="d-flex justify-content-center"
              >
                <feather-icon
                  v-b-tooltip.hover.top="$t('Edit')"
                  icon="EditIcon"
                  size="25"
                  style="color: green"
                  class="mr-1"
                  @click="$bvModal.show('edit-division'), divisionData = data "
                />
                <feather-icon
                  v-b-modal.delete-division
                  v-b-tooltip.hover.top="$t('Delete')"
                  icon="Trash2Icon"
                  size="25"
                  style="color: red"
                  @click="divisionId = data.item.id"
                />
              </div> </template></b-table>

        </div>
      </b-card>
    </div>

  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BButton,
  BFormSelect,
  BCard,
  // BCardHeader,
  BCardBody,
  // BFormInput,
  BTable,
  VBTooltip,
  BModal,

} from "bootstrap-vue"
import CreateDivision from "@/component/division/division-modal.vue"
import deleteDiv from "@/pages/alert-modal.vue"
import SAJToast from '@/component/saj-toastification.vue'
// import { mapGetters } from 'vuex'
// import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormSelect,
    // BFormInput,
    // vSelect,
    BCard,
    // BCardHeader,
    BCardBody,
    BTable,
    CreateDivision,
    BModal,
    deleteDiv,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {

  },
  data() {
    return {
      subsidiaryID: '',
      selectedSub: null,
      subsidiaryList: [{ text: `${this.$i18n.t('Choose Subsidiary')}`, value: null, disabled: true }],
      show: false,
      DivisionList: [],
      fields: [
        {
          key: 'index', label: 'No.', thClass: 'text-center',
        },
        {
          key: 'division', label: 'Division', thClass: 'text-center', tdClass: 'text-center',
        },
        { key: 'person', label: 'Person Assigned', thClass: 'text-center' },
        { key: 'action', label: 'Action', thClass: 'text-center' },
      ],
      divisionData: null,
      divisionId: null,
    }
  },
  computed: {
    // ...mapGetters(['roles']),
    isEnglish() {
      return this.$i18n.locale === 'en'
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$i18n.locale': function () {
      this.subsidiaryList = this.subsidiaryList.map(x => {
        if (x.value === null){
          return {
            ...x,
            text: this.$t('Choose Subsidiary'),
          }
        }
        return x
      })
    },
  },
  mounted() {
    // this.getDivision()
    this.getSubsidiaryList()

    // console.log("group", this.group)
  },
  methods: {
    getDivision(){
      this.$axios.get(`${this.$baseUrl}/divisions/get_all_head_of_division?subsidiary_id=${this.selectedSub}`).then(response => {
        if (response.data.success){
          this.DivisionList = response.data.data
          // console.log('Division List', this.DivisionList)
        } else {
          this.DivisionList = []
        }
      }).catch(() => {
        this.DivisionList = []
      })
    },
    getSubsidiaryList(){
      this.$axios.get(`${this.$baseUrl}/subsidiary/getAll`).then(res => {
        const sub = res.data.data.subsidiaries

        sub.forEach(data => {
          this.subsidiaryList.push({
            text: data.subsidiary_name,
            value: data.id,
          })
        })
      })
    },
    clearSearch(){
      this.selectedSub = null
      this.show = false
    },
    deleteDivision(){
      const data = new FormData()
      data.append('division_id', this.divisionId)
      this.$axios.post(`${this.$baseUrl}/divisions/delete_divisions`, data).then(() => {
        this.$toast(
          {
            component: SAJToast,
            props: {
              title: `${this.$t(`Successfully deleted`)}.`,
              icon: 'Trash2Icon',
              variant: 'success',
              titleColor: '#000',
              iconBg: '#e80202',
            },
          },
          {
            position: "top-right",
            type: 'info',
          },
        )
        this.$emit('close')
        this.getDivision()
      }).catch(() => {
        // const res = error.response.data.message
        this.$toast(
          {
            component: SAJToast,

            props: {
              title: `${this.$t('Division cannot be deleted because there are departments that still exist in that division')}.`,
              icon: 'XIcon',
              iconBg: '#e80202',
              variant: 'success',
              titleColor: '#000',
            },
          },
          {
            position: "top-right",
            type: 'info',
          },
        )
      })
    },
  },
}
</script>

<style scoped>

.sini{
    display: block;
    width: 100%;
    height: 3.714rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.45;
}
</style>
